import DropPage, {FileDataType} from "pages/Upload/DropPage";
import SuccessPage from "pages/Upload/SuccessPage";
import SharedPage from "pages/Upload/SharedPage";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { UpdateHistory } from "utils/UpdateHistory";


const Upload = (): JSX.Element => {
  const [fileData, setFileData] = useState<FileDataType>(undefined);
  const [ownPasswordStatus, setOwnPasswordStatus] = useState(false);
  const [sharedPageStatus, setSharedPageStatus] = useState(false);
  const [reloadStatus, setReloadStatus] = useState(undefined);
  const [passwordValue, setPasswordValue] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [url, setUrl] = useState('Loading...');
  const [id, setId] = useState(null);

  useEffect(() => {
    if (reloadStatus) {
      setUrl('Loading...')
      setPasswordValue(undefined)
      setId(undefined)
      setIsUpload(false);
      setSharedPageStatus(false);
      setFileData(undefined);
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  useEffect(() => {
    if (id && passwordValue) {
      if (ownPasswordStatus) {
        setUrl(`${process.env.REACT_APP_URL}/download/?request_id=${id}`);
      } else {
        setUrl(`${process.env.REACT_APP_URL}/download/?request_id=${id}&pw=${passwordValue}`);
      }
    }
  }, [passwordValue, id, ownPasswordStatus]);

  useEffect(() => {
    if (url && url != 'Loading...')
      UpdateHistory(url, fileData, ownPasswordStatus, id)
  }, [url])

  return (
      <BoxParent>
        <BoxContainer>
          {sharedPageStatus ?
            <SharedPage
              setReloadStatus={setReloadStatus}
              link={url} /> :
            !isUpload ?
              <DropPage
                fileData={fileData}
                setIsUpload={setIsUpload}
                setFileData={setFileData}
                setReloadStatus={setReloadStatus}
              /> :
              <SuccessPage
                setId={setId}
                fileData={fileData}
                passwordValue={passwordValue}
                setReloadStatus={setReloadStatus}
                setPasswordValue={setPasswordValue}
                ownPasswordStatus={ownPasswordStatus}
                setSharedPageStatus={setSharedPageStatus}
                setOwnPasswordStatus={setOwnPasswordStatus}
              />}
        </BoxContainer>
      </BoxParent>
  );
};

export const BoxParent = styled.div`
  @media screen and (max-width: 850px) {
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 600px;
  height: 450px;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #E5E8E8;
  border-radius: 32px;
  z-index: 1;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  overflow: hidden;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

interface ButtonProps {
  isNotClickable?: boolean;
}

export const Button = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 12px;
  background: ${({ isNotClickable }) => isNotClickable ? '#cecccc' : '#FF844C'};
  text-align: center;
  color: white;
  font-size: 16px;
  cursor: ${({ isNotClickable }) => isNotClickable ? 'not-allowed' : 'pointer'};
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  font-weight: bold;

  :hover {
    background: ${({ isNotClickable }) => isNotClickable ? '' : 'rgba(255, 132, 76, 0.8)'};
  }
`;

export default Upload;