import React, {useCallback, useEffect} from "react";
import {FileUploadInfo} from "utils/UpdateHistory";
import HistoryRow from "pages/History/HistoryRow";
import useLoadHistory from "utils/useLoadHistory";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";

const History = (): JSX.Element => {
  const {data: listData, setData: setList} = useLoadHistory();
  const navigate = useNavigate();

  const handleClickGoHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    window.localStorage.setItem("uploadHistory", JSON.stringify(listData));
  }, [listData]);

  return (
    <MainContainer>
      <TopBar>
        <Title>History</Title>
        <ButtonHome onClick={handleClickGoHome}>Go home</ButtonHome>
      </TopBar>
      <HistoryContainer>
        <HistoryHeader>
          <NameContainer>
            File name
          </NameContainer>
          <DateContainer>
            Date
          </DateContainer>
          <TypeContainer>
            Type
          </TypeContainer>
          <PasswordContainer>
            Lock
          </PasswordContainer>
          <ActionContainer>
            Action
          </ActionContainer>
        </HistoryHeader>
        <RowsContainer>
          {listData.map((file: FileUploadInfo, index) => {
            return (
              <HistoryRow
                file={file}
                index={index}
                setData={setList}
                key={`${file.fileName}-${index}`}
              />
            )
          })}
        </RowsContainer>
      </HistoryContainer>
    </MainContainer>
  )
};

const MainContainer = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  z-index: 10;
  margin-bottom: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  background: white;
`;

const Title = styled.h1`
  margin: 0;
  color: #3D3D3D;
  font-size: 16px;
`;

const ButtonHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D3D3D;
  height: 100%;
  width: 100px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  cursor: pointer;

  :hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  }
`;

const HistoryContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
`;

const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`;

const RowsContainer = styled.div`
  width: 100%;
  padding: 8px 0;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 250px;
  padding: 12px;
  box-sizing: border-box;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 120px;
  padding: 4px;
  box-sizing: border-box;
`;

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70px;
  padding: 12px;
  box-sizing: border-box;
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70px;
  padding: 12px;
  box-sizing: border-box;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 170px;
  padding: 12px;
  box-sizing: border-box;
`;

export default History;