interface ConvertFileSizesProps {
  size: number;
}

export const convertFileSize = ({size}: ConvertFileSizesProps) => {
  if (!size) {
    return '...';
  }
  const sizeMap = ["kB", "MB", "GB"];
  let unit = 0;
  let result = size;

  for (let i = 0; i < 3; i++) {
    if (size > Math.pow(10, 3 * (i + 1))) {
      unit = i;
    }
  }
  result = size / Math.pow(10, 3 * (unit + 1))
  return `${result.toFixed(1)} ${sizeMap[unit]}`;
};
