import React, {useEffect, useState} from "react";
import styled from "styled-components";

export type FilesType = 'image' | 'audio' | 'video' | 'application' | 'text' | '';

interface FileIconProps {
  type: FilesType;
}

const FileIcon = ({ type }: FileIconProps) => {
  const [typeIcon, setTypeIcon] = useState('default');

  useEffect(() => {
    if (type === '') {
      setTypeIcon('default');
    } else {
      setTypeIcon(type);
    }
  }, [type]);

  return (
    <Icon src={`/assets/FileType/${typeIcon}.png`} />
  )
};

const Icon = styled.img`
  height: 100%;
  margin-right: 5px;
`;

export default FileIcon;