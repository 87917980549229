import styled from "styled-components";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";

const TopContainer = () => {
  const navigate = useNavigate();

  const handleClickHistory = useCallback(() => {
    navigate('/history');
  }, [navigate]);

  return (
    <Container>
      <MainLogo src={'/logo/logo_white.svg'}/>
      <MenuContainer>
        <Menu>
          <MenuButton>How ?</MenuButton>
          <MenuButton>About us</MenuButton>
          <MenuButton onClick={handleClickHistory} >History</MenuButton>
        </Menu>
      </MenuContainer>
    </Container>
  )
};

const Container = styled.style`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

const MainLogo = styled.img`
  height: 120px;

  @media screen and (max-width: 400px) {
    height: 80px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
`;

const Menu = styled.div`
  display: flex;
  width: 470px;
  height: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  overflow: hidden;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #3D3D3D;

  :not(:first-child) {
    border-left: 1px solid #d7d6d6;
  }

  :hover {
    background: rgba(61, 61, 61, 0.1);
    color: rgba(155, 0, 59, 1);
  }
`;


export default TopContainer;