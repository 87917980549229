import React, {useMemo} from "react";
import styled from "styled-components";
import TopContainer from "pages/Upload/BackgroundComponent/TopContainer";
import FooterContainer from "pages/Upload/BackgroundComponent/FooterContainer";
import Upload from "pages/Upload";
import Download from "pages/Download";

interface HomeProps {
  pageType: 'Upload' | 'Download';
}

const Home = ({ pageType = 'Upload' }: HomeProps): JSX.Element => {
  const backgroundId = useMemo(() => (Math.floor(Math.random() * 22) + 1), []);

  return (
    <Container>
      <Background src={`/assets/imageBackground/back${backgroundId}.jpg`}/>
      <TopContainer />
      {pageType === 'Upload' ? <Upload /> : <Download />}
      <FooterContainer />
    </Container>
  )
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  user-select: none;
  overflow: hidden;
`;

export const Background = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
`;

export default Home;