import {backendURL} from "./setBackend";

export const postFileIpfs = async (data: string): Promise<string> => backendURL
  .post('/ipfs/upload', {
    file: data
  })
  .then((res) => {
    return res.data;
  })
  .catch((error) => {
    console.error(`error: ${error}`);
    return false;
  });