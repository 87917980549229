import {backendURL} from "./setBackend";

export const deleteFileIpfs = async (request_id: string): Promise<string> =>
  backendURL
    .delete('/ipfs/delete', {
      params: {
        request_id
      }
    })
    .then((res) => {
      return "Successfully deleted";
    })
    .catch((error) => {
      console.error(`error: ${error}`);
      return error;
    });
