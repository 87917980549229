import styled from "styled-components";
import React from "react";

const FooterContainer = () => {
  return (
    <Container>
      <SmallLogo src={'/logo/icon_white.svg'}/>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  min-height: 30px;
  background: rgb(26, 26, 26);
  padding: 4px 12px;
  box-sizing: border-box;
`;

const SmallLogo = styled.img`
  height: 100%;
`;

export default FooterContainer;