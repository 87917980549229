import {backendURL} from "./setBackend";

export const getFileIpfs = async (data: string): Promise<string> =>
  backendURL
    .get('/ipfs/get', {
      params: {
        request_id: data
      }
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.error(`error: ${error}`);
      return "";
    });


export const checkFileIdExist = async (id: string): Promise<boolean> =>
backendURL
  .get('/ipfs/getStatus', {
    params: {
      request_id: id
    }
  })
  .then((res) => {
    return true;
  })
  .catch((error) => {
    console.error(`error: ${error}`);
    return false;
  });
