import {useMemo, useState} from "react";
import {FileUploadInfo} from "utils/UpdateHistory";

interface UseLoadHistoryProps {
  data: FileUploadInfo[];
  setData: any;
}

const useLoadHistory = (): UseLoadHistoryProps => {
  const historyData = useMemo(() => {
    return window.localStorage.getItem("uploadHistory");
  }, [window.localStorage]);

  const [data, setData] = useState(historyData ? JSON.parse(historyData).reverse() : []);
  return {data, setData};
};

export default useLoadHistory;