import {ActionContainer, DateContainer, NameContainer, PasswordContainer, TypeContainer} from "pages/History/index";
import FileIcon, {FilesType} from "pages/Upload/DropPage/FileIcon";
import {FileUploadInfo} from "utils/UpdateHistory";
import React, {useCallback} from "react";
import styled from "styled-components";
import {deleteFileIpfs} from "server/deleteRoutes";

interface HistoryRowProps {
  index: number;
  file: FileUploadInfo;
  setData: (all: (old) => any) => void;
}

const HistoryRow = ({ index, file, setData }: HistoryRowProps): JSX.Element => {
  // const deleteHistoryRow = async (index: number, data: FileUploadInfo[]) => {
  //   const res = await deleteFileIpfs(data[index].requestId);
  //   if (res === "Successfully deleted") {
  //     data = data.filter((elem, i) => index !== i);
  //     window.localStorage.setItem("uploadHistory", JSON.stringify(data));
  //   }
  // };
  //         {/*<Icon onClick={() => deleteHistoryRow(index, listData).then(() => setListData(loadHistoryInfo()))} src={'/assets/trash-outline.svg'}/>*/}

  const handleClickDeleteLink = useCallback(async () => {
    const result = await deleteFileIpfs(file.requestId);

    if (result === "Successfully deleted") {
      setData((old) => old.filter((elem) => elem != file));
    }
  }, [file]);

  const handleClickOpenLink = useCallback(() => {
    window.open(file.url, '_blank');
  }, [file.url]);

  const getDateFormat = useCallback(() => {
    const data = file.date.toString().split('T');
    const date = data[0].split('-');
    const time = data[1].split(':');

    return `${date[2]} / ${date[1]} / ${date[0]}\n${time[0]}:${time[1]}`;
  }, [file.date]);

  return (
    <RowParentContainer isGrey={index % 2 == 0}>
      <NameContainer>
        <NameRow>{file.fileName}</NameRow>
      </NameContainer>
      <DateContainer>
        <DateRow>{getDateFormat()}</DateRow>
      </DateContainer>
      <TypeContainer>
        <TypeRow>
          <FileIcon type={file.type.split('/')[0] as FilesType}/>
        </TypeRow>
      </TypeContainer>
      <PasswordContainer>
        <PasswordRow src={file.lock ? '/assets/lock.svg' : 'assets/unlock.svg'}/>
      </PasswordContainer>
      <ActionContainer>
        <IconsContainer>
          <Icon onClick={handleClickOpenLink} src={'/assets/eye-outline.svg'}/>
          <Icon onClick={handleClickDeleteLink} src={'/assets/trash-outline.svg'}/>
        </IconsContainer>
      </ActionContainer>
    </RowParentContainer>
  )
};

interface ContainerProps {
  isGrey: boolean;
}

export const RowParentContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background: ${({isGrey}) => isGrey ? '#F6F6F6' : '#FFF'};
  overflow: hidden;
  margin-bottom: 16px;
`;

export const NameRow = styled.p`
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DateRow = styled.p`
  color: #3D3D3D;
  margin: 0;
  max-width: 120px;
  text-align: center;
`;

export const TypeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

export const PasswordRow = styled.img`
  padding: 12px;
  box-sizing: border-box;
`;

const IconsContainer = styled.div`
  height: 28px;
  display: flex;
`;

const Icon = styled.img`
  margin: 0 18px;
  cursor: pointer;
`;

export default HistoryRow;