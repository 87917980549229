export interface FileUploadInfo {
    url: string,
    type: string,
    lock: boolean,
    fileName: string,
    date: Date,
    requestId: string
  }

export const UpdateHistory = (url: string, {name, type}: {name?: string, type?: string }, ownPasswordStatus: boolean,  requestId: string) => {
  const uploadHistoryString = window.localStorage.getItem("uploadHistory");
  let listData : [FileUploadInfo] = uploadHistoryString ? JSON.parse(uploadHistoryString) : [];

  listData.push({requestId, fileName: name, type, url, lock: ownPasswordStatus, date: new Date()})
  window.localStorage.setItem("uploadHistory", JSON.stringify(listData))
}
